<template >
    <div class="error_page">
        <h1>Requested item not found</h1>
    </div>
</template>
<script>
export default {
    name:'Error404'
}
</script>
<style >
 .error_page {
    text-align: center; 
    padding: 213px 10px;
}
</style>